export const ValueOfNormByZPositive = {
  "000": 0.5,
  "001": 0.504,
  "002": 0.508,
  "003": 0.512,
  "004": 0.516,
  "005": 0.5199,
  "006": 0.5239,
  "007": 0.5279,
  "008": 0.5319,
  "009": 0.5359,
  "010": 0.5398,
  "011": 0.5438,
  "012": 0.5478,
  "013": 0.5517,
  "014": 0.5557,
  "015": 0.5596,
  "016": 0.5636,
  "017": 0.5675,
  "018": 0.5714,
  "019": 0.5753,
  "020": 0.5793,
  "021": 0.5832,
  "022": 0.5871,
  "023": 0.591,
  "024": 0.5948,
  "025": 0.5987,
  "026": 0.6026,
  "027": 0.6064,
  "028": 0.6103,
  "029": 0.6141,
  "030": 0.6179,
  "031": 0.6217,
  "032": 0.6255,
  "033": 0.6293,
  "034": 0.6331,
  "035": 0.6368,
  "036": 0.6406,
  "037": 0.6443,
  "038": 0.648,
  "039": 0.6517,
  "040": 0.6554,
  "041": 0.6591,
  "042": 0.6628,
  "043": 0.6664,
  "044": 0.67,
  "045": 0.6736,
  "046": 0.6772,
  "047": 0.6808,
  "048": 0.6844,
  "049": 0.6879,
  "050": 0.6915,
  "051": 0.695,
  "052": 0.6985,
  "053": 0.7019,
  "054": 0.7054,
  "055": 0.7088,
  "056": 0.7123,
  "057": 0.7157,
  "058": 0.719,
  "059": 0.7224,
  "060": 0.7257,
  "061": 0.7291,
  "062": 0.7324,
  "063": 0.7357,
  "064": 0.7389,
  "065": 0.7422,
  "066": 0.7454,
  "067": 0.7486,
  "068": 0.7517,
  "069": 0.7549,
  "070": 0.758,
  "071": 0.7611,
  "072": 0.7642,
  "073": 0.7673,
  "074": 0.7704,
  "075": 0.7734,
  "076": 0.7764,
  "077": 0.7794,
  "078": 0.7823,
  "079": 0.7852,
  "080": 0.7881,
  "081": 0.791,
  "082": 0.7939,
  "083": 0.7967,
  "084": 0.7995,
  "085": 0.8023,
  "086": 0.8051,
  "087": 0.8078,
  "088": 0.8106,
  "089": 0.8133,
  "090": 0.8159,
  "091": 0.8186,
  "092": 0.8212,
  "093": 0.8238,
  "094": 0.8264,
  "095": 0.8289,
  "096": 0.8315,
  "097": 0.834,
  "098": 0.8365,
  "099": 0.8389,
  100: 0.8413,
  101: 0.8438,
  102: 0.8461,
  103: 0.8485,
  104: 0.8508,
  105: 0.8531,
  106: 0.8554,
  107: 0.8577,
  108: 0.8599,
  109: 0.8621,
  110: 0.8643,
  111: 0.8665,
  112: 0.8686,
  113: 0.8708,
  114: 0.8729,
  115: 0.8749,
  116: 0.877,
  117: 0.879,
  118: 0.881,
  119: 0.883,
  120: 0.8849,
  121: 0.8869,
  122: 0.8888,
  123: 0.8907,
  124: 0.8925,
  125: 0.8944,
  126: 0.8962,
  127: 0.898,
  128: 0.8997,
  129: 0.9015,
  130: 0.9032,
  131: 0.9049,
  132: 0.9066,
  133: 0.9082,
  134: 0.9099,
  135: 0.9115,
  136: 0.9131,
  137: 0.9147,
  138: 0.9162,
  139: 0.9177,
  140: 0.9192,
  141: 0.9207,
  142: 0.9222,
  143: 0.9236,
  144: 0.9251,
  145: 0.9265,
  146: 0.9279,
  147: 0.9292,
  148: 0.9306,
  149: 0.9319,
  150: 0.9332,
  151: 0.9345,
  152: 0.9357,
  153: 0.937,
  154: 0.9382,
  155: 0.9394,
  156: 0.9406,
  157: 0.9418,
  158: 0.9429,
  159: 0.9441,
  160: 0.9452,
  161: 0.9463,
  162: 0.9474,
  163: 0.9484,
  164: 0.9495,
  165: 0.9505,
  166: 0.9515,
  167: 0.9525,
  168: 0.9535,
  169: 0.9545,
  170: 0.9554,
  171: 0.9564,
  172: 0.9573,
  173: 0.9582,
  174: 0.9591,
  175: 0.9599,
  176: 0.9608,
  177: 0.9616,
  178: 0.9625,
  179: 0.9633,
  180: 0.9641,
  181: 0.9649,
  182: 0.9656,
  183: 0.9664,
  184: 0.9671,
  185: 0.9678,
  186: 0.9686,
  187: 0.9693,
  188: 0.9699,
  189: 0.9706,
  190: 0.9713,
  191: 0.9719,
  192: 0.9726,
  193: 0.9732,
  194: 0.9738,
  195: 0.9744,
  196: 0.975,
  197: 0.9756,
  198: 0.9761,
  199: 0.9767,
  200: 0.9772,
  201: 0.9778,
  202: 0.9783,
  203: 0.9788,
  204: 0.9793,
  205: 0.9798,
  206: 0.9803,
  207: 0.9808,
  208: 0.9812,
  209: 0.9817,
  210: 0.9821,
  211: 0.9826,
  212: 0.983,
  213: 0.9834,
  214: 0.9838,
  215: 0.9842,
  216: 0.9846,
  217: 0.985,
  218: 0.9854,
  219: 0.9857,
  220: 0.9861,
  221: 0.9864,
  222: 0.9868,
  223: 0.9871,
  224: 0.9875,
  225: 0.9878,
  226: 0.9881,
  227: 0.9884,
  228: 0.9887,
  229: 0.989,
  230: 0.9893,
  231: 0.9896,
  232: 0.9898,
  233: 0.9901,
  234: 0.9904,
  235: 0.9906,
  236: 0.9909,
  237: 0.9911,
  238: 0.9913,
  239: 0.9916,
  240: 0.9918,
  241: 0.992,
  242: 0.9922,
  243: 0.9925,
  244: 0.9927,
  245: 0.9929,
  246: 0.9931,
  247: 0.9932,
  248: 0.9934,
  249: 0.9936,
  250: 0.9938,
  251: 0.994,
  252: 0.9941,
  253: 0.9943,
  254: 0.9945,
  255: 0.9946,
  256: 0.9948,
  257: 0.9949,
  258: 0.9951,
  259: 0.9952,
  260: 0.9953,
  261: 0.9955,
  262: 0.9956,
  263: 0.9957,
  264: 0.9959,
  265: 0.996,
  266: 0.9961,
  267: 0.9962,
  268: 0.9963,
  269: 0.9964,
  270: 0.9965,
  271: 0.9966,
  272: 0.9967,
  273: 0.9968,
  274: 0.9969,
  275: 0.997,
  276: 0.9971,
  277: 0.9972,
  278: 0.9973,
  279: 0.9974,
  280: 0.9974,
  281: 0.9975,
  282: 0.9976,
  283: 0.9977,
  284: 0.9977,
  285: 0.9978,
  286: 0.9979,
  287: 0.9979,
  288: 0.998,
  289: 0.9981,
  290: 0.9981,
  291: 0.9982,
  292: 0.9982,
  293: 0.9983,
  294: 0.9984,
  295: 0.9984,
  296: 0.9985,
  297: 0.9985,
  298: 0.9986,
  299: 0.9986,
  300: 0.9987,
  301: 0.9987,
  302: 0.9987,
  303: 0.9988,
  304: 0.9988,
  305: 0.9989,
  306: 0.9989,
  307: 0.9989,
  308: 0.999,
  309: 0.999,
};
export const ValueOfNormByZNegative = {
  300: 0.0013,
  301: 0.0013,
  302: 0.0013,
  303: 0.0012,
  304: 0.0012,
  305: 0.0011,
  306: 0.0011,
  307: 0.0011,
  308: 0.001,
  309: 0.001,
  290: 0.0019,
  291: 0.0018,
  292: 0.0018,
  293: 0.0017,
  294: 0.0016,
  295: 0.0016,
  296: 0.0015,
  297: 0.0015,
  298: 0.0014,
  299: 0.0014,
  280: 0.0026,
  281: 0.0025,
  282: 0.0024,
  283: 0.0023,
  284: 0.0023,
  285: 0.0022,
  286: 0.0021,
  287: 0.0021,
  288: 0.002,
  289: 0.0019,
  270: 0.0035,
  271: 0.0034,
  272: 0.0033,
  273: 0.0032,
  274: 0.0031,
  275: 0.003,
  276: 0.0029,
  277: 0.0028,
  278: 0.0027,
  279: 0.0026,
  260: 0.0047,
  261: 0.0045,
  262: 0.0044,
  263: 0.0043,
  264: 0.0041,
  265: 0.004,
  266: 0.0039,
  267: 0.0038,
  268: 0.0037,
  269: 0.0036,
  250: 0.0062,
  251: 0.006,
  252: 0.0059,
  253: 0.0057,
  254: 0.0055,
  255: 0.0054,
  256: 0.0052,
  257: 0.0051,
  258: 0.0049,
  259: 0.0048,
  240: 0.0082,
  241: 0.008,
  242: 0.0078,
  243: 0.0075,
  244: 0.0073,
  245: 0.0071,
  246: 0.0069,
  247: 0.0068,
  248: 0.0066,
  249: 0.0064,
  230: 0.0107,
  231: 0.0104,
  232: 0.0102,
  233: 0.0099,
  234: 0.0096,
  235: 0.0094,
  236: 0.0091,
  237: 0.0089,
  238: 0.0087,
  239: 0.0084,
  220: 0.0139,
  221: 0.0136,
  222: 0.0132,
  223: 0.0129,
  224: 0.0125,
  225: 0.0122,
  226: 0.0119,
  227: 0.0116,
  228: 0.0113,
  229: 0.011,
  210: 0.0179,
  211: 0.0174,
  212: 0.017,
  213: 0.0166,
  214: 0.0162,
  215: 0.0158,
  216: 0.0154,
  217: 0.015,
  218: 0.0146,
  219: 0.0143,
  200: 0.0228,
  201: 0.0222,
  202: 0.0217,
  203: 0.0212,
  204: 0.0207,
  205: 0.0202,
  206: 0.0197,
  207: 0.0192,
  208: 0.0188,
  209: 0.0183,
  190: 0.0287,
  191: 0.0281,
  192: 0.0274,
  193: 0.0268,
  194: 0.0262,
  195: 0.0256,
  196: 0.025,
  197: 0.0244,
  198: 0.0239,
  199: 0.0233,
  180: 0.0359,
  181: 0.0351,
  182: 0.0344,
  183: 0.0336,
  184: 0.0329,
  185: 0.0322,
  186: 0.0314,
  187: 0.0307,
  188: 0.0301,
  189: 0.0294,
  170: 0.0446,
  171: 0.0436,
  172: 0.0427,
  173: 0.0418,
  174: 0.0409,
  175: 0.0401,
  176: 0.0392,
  177: 0.0384,
  178: 0.0375,
  179: 0.0367,
  160: 0.0548,
  161: 0.0537,
  162: 0.0526,
  163: 0.0516,
  164: 0.0505,
  165: 0.0495,
  166: 0.0485,
  167: 0.0475,
  168: 0.0465,
  169: 0.0455,
  150: 0.0668,
  151: 0.0655,
  152: 0.0643,
  153: 0.063,
  154: 0.0618,
  155: 0.0606,
  156: 0.0594,
  157: 0.0582,
  158: 0.0571,
  159: 0.0559,
  140: 0.0808,
  141: 0.0793,
  142: 0.0778,
  143: 0.0764,
  144: 0.0749,
  145: 0.0735,
  146: 0.0721,
  147: 0.0708,
  148: 0.0694,
  149: 0.0681,
  130: 0.0968,
  131: 0.0951,
  132: 0.0934,
  133: 0.0918,
  134: 0.0901,
  135: 0.0885,
  136: 0.0869,
  137: 0.0853,
  138: 0.0838,
  139: 0.0823,
  120: 0.1151,
  121: 0.1131,
  122: 0.1112,
  123: 0.1093,
  124: 0.1075,
  125: 0.1056,
  126: 0.1038,
  127: 0.102,
  128: 0.1003,
  129: 0.0985,
  110: 0.1357,
  111: 0.1335,
  112: 0.1314,
  113: 0.1292,
  114: 0.1271,
  115: 0.1251,
  116: 0.123,
  117: 0.121,
  118: 0.119,
  119: 0.117,
  100: 0.1587,
  101: 0.1562,
  102: 0.1539,
  103: 0.1515,
  104: 0.1492,
  105: 0.1469,
  106: 0.1446,
  107: 0.1423,
  108: 0.1401,
  109: 0.1379,
  "090": 0.1841,
  "091": 0.1814,
  "092": 0.1788,
  "093": 0.1762,
  "094": 0.1736,
  "095": 0.1711,
  "096": 0.1685,
  "097": 0.166,
  "098": 0.1635,
  "099": 0.1611,
  "080": 0.2119,
  "081": 0.209,
  "082": 0.2061,
  "083": 0.2033,
  "084": 0.2005,
  "085": 0.1977,
  "086": 0.1949,
  "087": 0.1922,
  "088": 0.1894,
  "089": 0.1867,
  "070": 0.242,
  "071": 0.2389,
  "072": 0.2358,
  "073": 0.2327,
  "074": 0.2296,
  "075": 0.2266,
  "076": 0.2236,
  "077": 0.2206,
  "078": 0.2177,
  "079": 0.2148,
  "060": 0.2743,
  "061": 0.2709,
  "062": 0.2676,
  "063": 0.2643,
  "064": 0.2611,
  "065": 0.2578,
  "066": 0.2546,
  "067": 0.2514,
  "068": 0.2483,
  "069": 0.2451,
  "050": 0.3085,
  "051": 0.305,
  "052": 0.3015,
  "053": 0.2981,
  "054": 0.2946,
  "055": 0.2912,
  "056": 0.2877,
  "057": 0.2843,
  "058": 0.281,
  "059": 0.2776,
  "040": 0.3446,
  "041": 0.3409,
  "042": 0.3372,
  "043": 0.3336,
  "044": 0.33,
  "045": 0.3264,
  "046": 0.3228,
  "047": 0.3192,
  "048": 0.3156,
  "049": 0.3121,
  "030": 0.3821,
  "031": 0.3783,
  "032": 0.3745,
  "033": 0.3707,
  "034": 0.3669,
  "035": 0.3632,
  "036": 0.3594,
  "037": 0.3557,
  "038": 0.352,
  "039": 0.3483,
  "020": 0.4207,
  "021": 0.4168,
  "022": 0.4129,
  "023": 0.409,
  "024": 0.4052,
  "025": 0.4013,
  "026": 0.3974,
  "027": 0.3936,
  "028": 0.3897,
  "029": 0.3859,
  "010": 0.4602,
  "011": 0.4562,
  "012": 0.4522,
  "013": 0.4483,
  "014": 0.4443,
  "015": 0.4404,
  "016": 0.4364,
  "017": 0.4325,
  "018": 0.4286,
  "019": 0.4247,
  "000": 0.5,
  "001": 0.496,
  "002": 0.492,
  "003": 0.488,
  "004": 0.484,
  "005": 0.4801,
  "006": 0.4761,
  "007": 0.4721,
  "008": 0.4681,
  "009": 0.4641,
};
